import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const Fio = () => {
  const headingbanner = {
    title: `Fio`,
    content: `A D2C fashion e-commerce platform`,
  };

  const data = {
    images: ["fio-project.webp", "fio-project-2.webp"],
    firstAlt: "Landing Page Of D2C Fashion E-Commerce Platform",
    firstTitle: "Landing Page",
    secondAlt: "Product List Page Of D2C Fashion E-Commerce Platform",
    secondTitle: "Product List Page",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Ecommerce"],
      },
      {
        title: "What we did",
        text: ["Branding", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Website"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The brand aims to become the go-to choice for young professionals seeking stylish, sustainable, and affordable organic cotton T-shirts in the Indian market.`,
          `Young professionals in India are increasingly conscious of the environmental impact of their choices, including the clothing they wear. However, they face challenges in finding high-quality organic cotton T-shirts that align with their values. `,
          `Existing options often lack style, variety, and affordability, making it difficult for them to express their individuality while supporting sustainable fashion.`,
          `This gap in the market presents an opportunity for a direct-to-consumer (D2C) Indian brand to offer organic cotton T-shirts of exceptional quality, with contemporary designs that resonate with the style-conscious young professional demographic.`,
        ],
      },
      {
        title: "Solution",
        content: [
          `We conducted an in-depth analysis of the brand's identity, its value proposition and also identified the target audience, their preferences, needs, and online shopping behaviours.`,
          `We then designed the website with a clean, minimalistic aesthetic to reflect the brand's sophistication and to emphasize the premium quality of the T-shirts.`,
          `Additionally, we added customer testimonials as social proof and balanced the visual content with detailed product descriptions.`,
          `With our solution, we built a user-friendly interface that allowed customers to easily browse through different sections, explore various T-shirt patterns, and make purchases seamlessly. `,
        ],
      },
    ],
    image: ["fio-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Product Page",
    para: [
      `We meticulously created this product page keeping in mind the user's perspective. In this product page, we used equal space to show product images and descriptions for the user to look into the details of their selected option carefully and improve their shopping experience.`,
    ],
    content: {
      image: ["fio-projectone.webp"],
      imageAlt: "Product Page- Men's Section ",
      imageTitle: 'Product Page',
    },
  };

  const projectData2 = {
    title: "Section Page",
    para: [
      `With our design, customers get to experience a seamless online shopping process and indulge in a personalized, elevated fashion experience by offering top-quality products and unparalleled customer service.`,
    ],
    content: {
      image: ["fio-projecttwo.webp"],
      imageAlt: "Men's Section page for pure cotton T-shirts",
      imageTitle: 'Section Page',
    },
  };

  const projectData3 = {
    title: "Landing Page",
    para: [
      `We created this page to showcase the overview of all the categories that the brand offers to provide enhanced customer experience.`,
    ],
    content: {
      image: [
        "fio-projectthree.webp",
        "fio-projectthree-2.webp",
        "fio-projectthree-3.webp",
        "fio-projectthree-4.webp",
      ],
      firstAlt: 'Home Page to show product offerings',
      firstTitle: 'Home Page',
      secondAlt: 'Product Page with customer reviews',
      secondTitle: 'Product Page',
      thirdAlt: "Men's Section page to show various offerings",
      thirdTitle: 'Section Page',
      fourthAlt: 'Check the products added in the cart',
      fourthTitle: 'Your Cart',
    },
  };

  const conclusionData = [
    {
      para: [
        `FioTee is a Direct-to-Consumer (D2C) brand specializing in providing high-quality, pure cotton formal T-shirts.`,
        `Operating within the D2C market sector, the need for a website that accurately represented their brand positioning and effectively engaged with their target audience was of paramount importance.`,
        `Our primary objective was to create a website with a refined aesthetic appeal, minimalistic design, and an emphasis on product features to provide a premium experience for potential customers.`,
        `The product page design was primarily centred on highlighting the inherent quality of the product through the use of pertinent images, while simultaneously maintaining a balance between content volume and the inclusion of social proof elements.`,
        `This approach to design facilitates a user-friendly navigation experience, allowing customers to easily traverse different sections of the site and find patterns that best suit their preferences.`,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={14} />
      </div>
    </Layout>
  );
};

export default Fio;

export const Head = () => (
  <Seo title="Fio- D2C Fashion E-Commerce Case Study | Octet Design Studio" description="A D2C shopping platform offering pure cotton T-shirts. We did branding and offered UI UX Desiging and Shopify Development Services." />
)